import React from 'react';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import Button from '../../components/Button';

const IndexPage = () => (
  <Layout
    renderCallCTA
    headerWitdh="header"
    headerStyle="flex--space-between"
    text="In-network with Blue Shield of California"
  >
    <script
      dangerouslySetInnerHTML={{
        __html: `
     analytics.track('Calendly Booked')
    `,
      }}
    />
    <Segment id="15aFPY6NamUQUAN3oIEqSx0D2mNElDF7" />
    <Seo title="Confirmation" language="en" />
    <section data-theme="white">
      <div className="container container--md margin-top--lg margin-bottom--lg margin---none">
        <div className="grid grid--gap-lg flex--center-x flex--center-y">
          <div className="col col--12 col--sm-6 col--lg-9">
            <div className="text--component text--center list--checklist">
              <h2>Your call is scheduled!</h2>
              <p>
                Thank you for scheduling a call with Joint Academy! We are
                commited to helping people reduce their joint pain wherever
                and whenever. Our onboaring specialist will get in touch with
                you and help you get started.
              </p>
              <h3 className="margin-top--md text--md margin-bottom--xs">
                Want to get started right away?
              </h3>
              <Button
                dataGoal="Store Viewed"
                href="https://app.adjust.com/wz92pd8"
                text="Download the app"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
